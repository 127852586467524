:root {
  --lf_cell_bg_color: #fff;

  --lf_color_bg: #fff;
  --lf_color_bg_alter: #f2f2f2;
  --lf_color_font: #1a1a1a;
  --lf_color_font_inverted: #fff;
  --lf_color_alter: #999;
  --lf_color_alter_2: #666666;
  --lf_color_action: #156acb;
  --lf_color_action_hover: #217eea;
  --lf_color_action_bg: #063d7c;
  --lf_color_action_bg_hover: #156acb;
  --lf_color_action_color_inverted: #fff;
  --lf_color_constructive: #0e8722;
  --lf_color_destructive: #cb1515;
  --lf_color_destructive_hover: #e61717;

  --lf_size_smallest: 10px;
  --lf_size_smaller: 12px;
  --lf_size_small: 14px;
  --lf_size_normal: 16px;
  --lf_size_big: 18px;
  --lf_size_bigger: 20px;
  --lf_size_biggest: 22px;
  --lf_size_xl: 24px;
  --lf_size_xxl: 28px;
  --lf_size_xxl2: 32px;
  --lf_size_3xl: 36px;
  --lf_size_3xl2: 42px;
  --lf_size_4xl: 48px;
  --lf_size_5xl: 54px;
  --lf_size_6xl: 60px;
  --lf_size_7xl: 64px;
  --lf_size_8xl: 72px;

  --lf_unit_smaller: 2px;
  --lf_unit_small: 4px;
  --lf_unit_medium: 8px;
  --lf_unit_average: 12px;
  --lf_unit_normal: 16px;
  --lf_unit_large: 18px;
  --lf_unit_larger: 24px;
  --lf_unit_largest: 32px;
  --lf_unit_xl: 48px;
  --lf_unit_xxl: 64px;
  --lf_unit_3xl: 96px;

  --font-family-main: 'Noto Serif', serif;

  --lf_shadow_1: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 479px) {
  :root {
    --lf_size_xl: 22px;
    --lf_size_xxl: 24px;
    --lf_size_xxl2: 28px;
    --lf_size_3xl: 30px;
    --lf_size_3xl2: 32px;
    --lf_size_4xl: 34px;
    --lf_size_5xl: 36px;
    --lf_size_6xl: 38px;
    --lf_size_7xl: 40px;
    --lf_size_8xl: 42px;

    --lf_unit_smaller: 1px;
    --lf_unit_small: 2px;
    --lf_unit_medium: 4px;
    --lf_unit_average: 6px;
    --lf_unit_normal: 8px;
    --lf_unit_large: 10px;
    --lf_unit_larger: 12px;
    --lf_unit_largest: 16px;
    --lf_unit_xl: 24px;
    --lf_unit_xxl: 32px;
  }
}
