@import './vars.css';
@import url('https://fonts.googleapis.com/css?family=Noto+Serif&display=swap&subset=cyrillic');
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif', serif;
}

#root {
  position: relative;
  height: 100%;
}
